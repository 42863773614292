import { useThree, useLoader } from "@react-three/fiber";
import * as THREE from "three";
import {
  Lensflare,
  LensflareElement,
} from "three/examples/jsm/objects/Lensflare";
import { Text3D } from "@react-three/drei";
import { useEffect, useState, useMemo, useRef } from "react";
import Quest from "./Quest.jsx";

function SetBackground({ color }) {
  const { scene } = useThree();
  scene.background = new THREE.Color().setHSL(
    0.51,
    0.4,
    0.01,
    THREE.SRGBColorSpace
  );
  scene.backgroundIntensity = 0.1;
  scene.backgroundBlurriness = 0.5;
  scene.fog = new THREE.Fog(scene.background, 3500, 15000);
  return null;
}

function MouseLight() {
  const { viewport } = useThree();
  const [mousePos, setMousePos] = useState(new THREE.Vector3(0, 0, 1.2));
  const lightRef = useRef();
  const texture = useLoader(THREE.TextureLoader, "./lensflare0.png");

  useEffect(() => {
    if (lightRef.current) {
      const lensflare = new Lensflare();
      lensflare.addElement(
        new LensflareElement(texture, 100, 0, lightRef.current.color)
      );
      lightRef.current.add(lensflare);
      return () => lensflare.dispose();
    }
  }, [texture]);

  // Combined handler for both mouse and touch events
  const handlePointerMove = (event) => {
    // Get coordinates from either mouse or touch event
    const clientX = event.touches ? event.touches[0].clientX : event.clientX;
    const clientY = event.touches ? event.touches[0].clientY : event.clientY;

    const x = (clientX / window.innerWidth) * 2 - 1;
    const y = -(clientY / window.innerHeight) * 2 + 1;

    const worldX = (x * viewport.width) / 2;
    const worldY = (y * viewport.height) / 2;

    setMousePos(new THREE.Vector3(worldX, worldY, 1.2));
  };

  useEffect(() => {
    // Add both mouse and touch event listeners
    window.addEventListener("mousemove", handlePointerMove);
    window.addEventListener("touchmove", handlePointerMove);
    window.addEventListener("touchstart", handlePointerMove);

    return () => {
      window.removeEventListener("mousemove", handlePointerMove);
      window.removeEventListener("touchmove", handlePointerMove);
      window.removeEventListener("touchstart", handlePointerMove);
    };
  }, [viewport]);

  return (
    <pointLight
      ref={lightRef}
      position={[mousePos.x, mousePos.y, 1.2]}
      intensity={0.7}
      color={0xe9cced}
    />
  );
}

export default function Scene() {
  const { viewport } = useThree();
  const [mousePos, setMousePos] = useState(new THREE.Vector3(0, 0, 1.2));
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check if device is mobile
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768); // You can adjust this breakpoint
    };

    // Initial check
    checkMobile();
    // Add resize listener
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  // Combined handler for both mouse and touch events
  const handlePointerMove = (event) => {
    // Prevent default touch behavior
    event.preventDefault();

    // Get coordinates from either mouse or touch event
    const clientX = event.touches ? event.touches[0].clientX : event.clientX;
    const clientY = event.touches ? event.touches[0].clientY : event.clientY;

    const x = (clientX / window.innerWidth) * 2 - 1;
    const y = -(clientY / window.innerHeight) * 2 + 1;

    const worldX = (x * viewport.width) / 2;
    const worldY = (y * viewport.height) / 2;

    setMousePos(new THREE.Vector3(worldX, worldY, 1.2));
  };

  useEffect(() => {
    // Add both mouse and touch event listeners
    window.addEventListener("mousemove", handlePointerMove, { passive: false });
    window.addEventListener("touchmove", handlePointerMove, { passive: false });
    // window.addEventListener("touchstart", handlePointerMove, {
    //   passive: false,
    // });

    return () => {
      window.removeEventListener("mousemove", handlePointerMove);
      window.removeEventListener("touchmove", handlePointerMove);
      //   window.removeEventListener("touchstart", handlePointerMove);
    };
  }, [viewport]);

  const textMaterial = useMemo(
    () =>
      new THREE.MeshStandardMaterial({
        color: "white",
        metalness: 0.5,
        roughness: 0.5,
      }),
    []
  );

  const textConfig = {
    font: "./fonts/helvetiker_regular.typeface.json",
    size: 0.5,
    height: 0.005,
    curveSegments: 32,
    bevelEnabled: true,
    bevelThickness: 0.02,
    bevelSize: 0.02,
    bevelOffset: 0,
    bevelSegments: 5,
    material: textMaterial,
  };

  const comingPosition = isMobile ? [-1.25, 1, 0] : [-3.8, 0.75, 0];
  const soonPosition = isMobile ? [-0.85, -1.5, 0] : [1.8, -1, 0];

  return (
    <>
      <SetBackground color="#000000" />
      <MouseLight />
      <Text3D position={comingPosition} {...textConfig}>
        COMING
      </Text3D>
      <Quest mousePos={mousePos} />
      <Text3D position={soonPosition} {...textConfig}>
        SOON
      </Text3D>
    </>
  );
}
