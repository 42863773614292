import { useGLTF } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useRef } from "react";

export default function Quest({ mousePos }) {
  const quest = useGLTF("./model/Quest3New.glb");
  const questRef = useRef();

  useFrame(() => {
    if (questRef.current && mousePos) {
      // Make the model look at the mouse position
      questRef.current.lookAt(mousePos);
    }
  });

  return (
    <primitive
      ref={questRef}
      object={quest.scene}
      scale={8}
      position={[0, 0, 0]}
    />
  );
}
